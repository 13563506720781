<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <app-btn @click="configFormDialog.show()">
        <v-icon>mdi-account-plus-outline</v-icon> Add New Script
      </app-btn>
    </v-col>
    <material-card icon="mdi-account-group" icon-small color="primary" :title="selectedCompanyName + ' Scripts'">
      <v-card-text>
        <v-tabs v-model="tab" @change="onTabSelected">
          <v-tab v-for="group in groups" :key="group.id">
            {{ group.name }}
          </v-tab>
        </v-tabs>

        <div class="mt-5 d-flex" style="gap: 3rem" v-if="selectedGroup">
          <div>
            <span class="font-weight-bold" v-text="'Name: '" />
            <span v-text="selectedGroup.name" />
          </div>
          <div>
            <span class="font-weight-bold" v-text="'Device Path: '" />
            <span v-text="selectedGroup.devicePath" />
          </div>
          <div>
            <span class="font-weight-bold" v-text="'Created At: '" />
            <span v-text="$format.dateTime(selectedGroup.createdAt)" />
          </div>

          <div>
            <v-icon small color="info" @click="configFormDialog.show(selectedGroup, { index: tab })">
              mdi-pencil-circle-outline
            </v-icon>

            <v-icon class="ml-3" small color="error"
              @click="deleteDialog.show({ type: 'config', item: selectedGroup })">
              mdi-close-circle-outline
            </v-icon>
          </div>
        </div>

        <div class="mt-4 d-flex" style="flex-wrap: wrap; gap: 10px">
          <div v-for="file in groupFiles" class="d-inline-flex justify-center align-center py-2 pl-2 pr-8"
            style="position: relative; background: #f5f5f5; border-radius: 7.5px; cursor: pointer; gap: 7.5px">
            <v-icon x-large color="#749ef2">mdi-file</v-icon>
            <span>{{ file.name }}</span>
            <v-icon style="position: absolute; top:3px;right: 3px;" small color="error"
              @click="deleteDialog.show({ type: 'file', item: file })">
              mdi-close-circle-outline
            </v-icon>
          </div>
        </div>

        <v-expansion-panels class="mt-15" v-if="groups.length">
          <v-expansion-panel>
            <v-expansion-panel-header>Assign New File</v-expansion-panel-header>
            <v-expansion-panel-content class="pa-3 pb-0">
              <div class="d-flex align-center mt-5">
                <div style="cursor: pointer;" @click="dialogFilesLibrary.show()">
                  <v-text-field label="File" hide-details v-model="editedFile.fileName" disabled
                    prepend-icon="mdi-file-import" />
                </div>
                <v-text-field class="ml-4" label="Name" hide-details v-model="editedFile.name" />
                <app-btn class="ml-4 mt-1" @click="assignFile">
                  Add
                </app-btn>
              </div>

            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Add New File</v-expansion-panel-header>
            <v-expansion-panel-content class="pa-3 pb-0">

              <dropzone v-model="editedFile.file" @drop="onFileDrop" />
              <div class="d-flex align-center mt-5">
                <v-text-field label="Name" hide-details v-model="editedFile.name" />
                <app-btn class="ml-4 mt-1" @click="addFile">
                  Add
                </app-btn>
              </div>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <user-prompt v-model="deleteDialog" @confirm="deleteConfirm" />
        <files-library-dialog v-model="dialogFilesLibrary" @select="chooseFile" />
        <form-dialog v-model="configFormDialog" :default-value="defaultConfig" @save="saveConfig">
          <template v-slot:[`form`]="{ item }">
            <v-col cols="12">
              <v-text-field v-model="item.name" label="Name" />
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="item.devicePath" label="Device Path" />
            </v-col>
          </template>
        </form-dialog>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import FilesLibraryDialog from './components/custom/FilesLibraryDialog.vue'
import EditableConfigTable from './components/config/EditableConfigTable.vue'
import MultiConfigForm from './components/config/MultiConfigForm.vue'
import { get } from 'vuex-pathify'
import store from '../store'

export default {
  name: 'DataTablesView',
  components: { MultiConfigForm, EditableConfigTable, FilesLibraryDialog },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
      length15: (value) => value.length <= 15 || 'Maximum 15 characters.',
    },
    dialog: false,
    deleteDialog: null,
    dialogFilesLibrary: null,
    configFormDialog: null,
    selectedGroup: undefined,
    groups: [],
    groupFiles: [],
    editedIndex: - 1,
    editedItems: [],
    defaultConfig: {
      name: "",
      devicePath: "",
    },
    editedFile: {
      name: '',
      file: undefined,
      fileId: 0,
      fileName: ''
    },
    defaultFile: {
      name: '',
      file: undefined,
      fileId: 0,
      fileName: ''
    },
    search: undefined,
    referenceId: undefined,
    tab: undefined
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Add New Config' : 'Edit Config'
    },
    ...get('company', ['selectedCompanyId', 'selectedCompanyName']),
    companies() {
      return store.getters['company/getCompanies']
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    selectedCompanyId() {
      this.getFileGroups(this.selectedCompanyId).then(data => {
        this.groups = data
      })
    },
  },

  created() {
    this.getFileGroups(this.selectedCompanyId).then(data => {
      this.groups = data
    })
  },

  methods: {

    async getFileGroups(CompanyId) {
      try {
        const response = await this.$http.get('DeviceConfig/GetDeviceConfigsByCompany', {
          params: {
            CompanyId
          },
        })

        return response.data || []
      } catch (error) {
        return []
      }
    },

    async getGroupFiles(DeviceConfigId) {
      try {
        const response = await this.$http.get('DeviceConfig/GetFilePathsByDeviceConfigs', {
          params: {
            DeviceConfigId
          },
        })

        return response.data || []
      } catch (error) {
        return []
      }
    },

    close() {
      this.editedItems = []
      this.dialog = false
    },

    save() {
      if (!this.editedItems || !this.editedItems.length) {
        return
      }

      if (this.selectedCompanyId === 0) {
        this.$toast.error('Company not found.', 'Error', {
          position: 'topRight',
        })
        return
      }

      this.$http.post('/CompanyConfiguration/CreateCompanyConfigurations', {
        companyId: this.selectedCompanyId,
        createConfigurations: this.editedItems,
      }).then((response) => {
        if (response.data.success) {
          this.$toast.success('Config added successfully.', 'Success', {
            position: 'topRight',
          })
          this.items.unshift(response.data.data)
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })

      this.close()
    },
    update(item) {
      if (!item.configurationId && item.configurationId === 0) {
        return
      }

      this.$http.put(`/DeviceConfiguration/${item.configurationId}`, item).then(response => {
        if (response.data.success) {
          this.$toast.success('Config updated successfully.', 'Success', {
            position: 'topRight',
          })
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })
    },
    remove(item) {
      this.$http.delete(`/CompanyConfiguration/DeleteCompanyConfiguration?id=${item.id}`).then(response => {
        if (response.data.success) {
          this.$toast.success('Config removed successfully.', 'Success', {
            position: 'topRight',
          })

          const index = this.items.indexOf(item)
          this.items.splice(index, 1)
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })
    },

    chooseReference() {
      this.editedItems = []

      if (!this.referenceId || this.referenceId === 0) {
        return
      }

      this.getCompanyConfigurations(this.referenceId).then(data => {
        this.editedItems = data.map(x => ({
          name: x.name,
          type: x.type,
          value: x.value,
          description: x.description,
        }))
      })
    },
    onTabSelected(index) {
      this.selectedGroup = this.groups[index];
      this.groupFiles = []

      if (!this.selectedGroup) {
        return
      }

      this.getGroupFiles(this.selectedGroup.id).then(data => {
        this.groupFiles = data
      })
    },
    deleteConfirm({ type, item }) {
      if (type === 'config') {
        this.$http.delete(`DeviceConfig/DeleteDeviceConfig?id=${item.id}`)
          .then((response) => {
            if (response.data.success) {
              const index = this.groups.indexOf(item)
              this.groups.splice(index, 1)
              this.$toast.success(response.data.message, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
          })
      } else if (type === 'file') {
        this.$http.delete(`DeviceConfigFilePath/id?id=${item.deviceConfigFilePathId}`)
          .then((response) => {
            if (response.data.success) {
              const index = this.groupFiles.indexOf(item)
              this.groupFiles.splice(index, 1)
              this.$toast.success(response.data.message, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
          })
      }
    },
    onFileDrop(file) {
      this.editedFile.file = file;
      this.editedFile.name = file.name
    },
    addFile() {
      const group = this.groups[this.tab]
      if (!group) {
        return
      }

      var form = new FormData()
      form.append('name', this.editedFile.name)
      form.append('configType', 1)
      form.append('deviceConfigId', group.id)
      form.append('companyId', group.companyId)
      form.append('file', this.editedFile.file)

      this.$http
        .post('DeviceConfig/AddFilesToConfig', form)
        .then((response) => {
          if (response.data.success) {
            this.groupFiles.push(response.data.data)
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
      this.clearFileItem()
    },
    assignFile() {
      const group = this.groups[this.tab]
      if (!group) {
        return
      }

      var form = new FormData()
      form.append('name', this.editedFile.name)
      form.append('configType', 1)
      form.append('deviceConfigId', group.id)
      form.append('companyId', group.companyId)
      form.append('filePathId', this.editedFile.fileId)

      this.$http
        .post('DeviceConfig/AssignFilesToConfig', form)
        .then((response) => {
          if (response.data.success) {
            this.groupFiles.push(response.data.data)
            this.$toast.success(response.data.message, 'Success', {
              position: 'topRight',
            })
          } else {
            this.$toast.error(response.data.message, 'Error', {
              position: 'topRight',
            })
          }
        })
      this.clearFileItem()
    },
    clearFileItem() {
      this.editedFile = Object.assign({}, this.defaultFile)
    },
    chooseFile(v) {
      this.editedFile.fileId = v.id
      this.editedFile.fileName = v.name
      this.editedFile.name = v.name
    },
    saveConfig(item, options) {
      if (options?.index > -1) {
        const editedItem = {
          name: item.name,
          configType: item.configType,
          devicePath: item.devicePath,
          companyId: item.companyId
        }

        this.$http
          .put(`DeviceConfig/UpdateDeviceConfig?id=${item.id}`, editedItem)
          .then((response) => {
            if (response.data.success) {
              this.groups.splice(options.index, 1, item)
              this.$toast.success(response.data.message, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
          })
      } else {
        const editedItem = item
        editedItem.companyId = this.selectedCompanyId

        this.$http
          .post('DeviceConfig/AddDeviceConfigToCompany', editedItem)
          .then((response) => {
            if (response.data.success) {
              this.groups.push(response.data.data)
              this.$toast.success(response.data.message, 'Success', {
                position: 'topRight',
              })
            } else {
              this.$toast.error(response.data.message, 'Error', {
                position: 'topRight',
              })
            }
          })
      }
    }
  },
}
</script>

<style>
.v-expansion-panel {
  background: #f5f5f5 !important;
}

.v-expansion-panel::before {
  box-shadow: none;
}
</style>
